import React, { useState, useEffect } from 'react';
import './FarmersTestimonials.css'

const testimonials = [
    {
      id: 1,
      first_h5: "Masaka DFA improves community livelihoods cassava value addition",
      message: "Nalubowa Sarah of Kayugi Group C in Mukungwe Sub County, Masaka District is one of the farmers who have embraced cassava value addition not only as a way of increasing food availability but also a business generating income as she reports “After receiving the planting materials, tarpaulins and the training on how to use the slicing machine, I picked interest in how to add value on cassava tubers through processing cassava chips”. “We extended our Cassava garden to 1.5 acres and in the first season we were able to produce 2,280Kgs of dried chips which we sold at an average price of Ushs 500 per Kg” she adds. “When we extended our Cassava garden to 2 acres, our latest harvest increased and we were able to chip and sell 2,850 Kgsof cassava chipsat an average price of Ushs. 510” Narrates MsNalubowa.",
      image: "assets/img/testimonial-5.jpg",
      second_h5: "Nalubowa Sarah and her family processing and packing cassava chips",
      span: "Kayugi Group C in Mukungwe Sub County, Masaka District",
    },
    {
      id: 2,
      first_h5: "Water harvesting technologies improving livelihoods",
      message: "By supporting farmers in water harvesting, a number of families in Masaka District have received underground water tanks of different capacities such as 10,000 liters, 15,000 liters. These structures have reduced on the time spent and distances moved when fetching water, irrigation of plantations especially in the dry season, some families with livestock animals report increased saving on water bills as well as improved social relationship with neighbours who fetch water especially in the dry season. Examples include Nankya Christine in Mukungwe Sub County who reported a saving of half the money she used to spend on water bills ever since MDFA supported her to construct a tank on her farm. Other farmers with tanks includeAkankwasa Elizabeth in Buwunga Sub County.",
      image: "assets/img/testimonial-9.jpg",
      second_h5: "Akankwasa Elizabeth",
      span: "Underground tank for Akankwasa Elizabeth",
    },
    {
      id: 3,
      first_h5: "Agroforestry utilizing waste lands in Masaka",
      message: "MDFA promoting climate change mitigation, a number of trees have been planted on boundaries, as woodlots, shade trees and for firewood. Through this, various pieces of wasted lands have been restored back to productivity. An example of such restored lands is at MrsBazanye Betty of Lwanunda Farmer Group in Buwunga Sub County where 1.5 acres of land was restored by planting a grivelia woodlot for firewood and timber provision.",
      image: "assets/img/testimonial-7.jpg",
      second_h5: "Mr.Bikanyulo",
      span: "1 acre woodlot established at Mr.Bikanyulo in Njumaga Village in Mukungwe Sub County",
    },
    {
      id: 4,
      first_h5: "Joint decision making and Household Road map a key to livelihood empowerment",
      message: "With empowerment of households in joint decision making, many families plan and work together. This has improved production and productivity in many families especially those that establish and implement vision journeys. An example of such a household is of Namuli Teddy of Twekolerewomens group in Lwabikere Village Mukungwe Sub County. She drew a vision journey of buying land which she achieved.",
      image: "assets/img/testimonial-8.jpg",
      second_h5: "Ms.Namuli",
      span: "Ms.Namuli posing in her newly bought land and building materials (bricks) in Lwabikere Village in Mukungwe Sub County",
    }
];
  

const FarmersTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleItems = 3; // Number of items to display at a time
  const middleIndex = Math.floor(visibleItems / 2); // Calculate the index of the middle item

  useEffect(() => {
    const interval = setInterval(() => {
      // Move to the next item
      if (currentIndex < testimonials.length - visibleItems) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        setCurrentIndex(0); // Reset to the first index after reaching the end
      }
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentIndex]);

  return (
    <div className="container-fluid bg-light bg-icon py-6">
        <div className="container">
            <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px', visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                <h1 className="display-5 mb-3">Testimonial Review</h1>
                <p>
                    Our Farmers, cooperatives and individuals (both small and large scale) narrates their Journeys to successful farming
                </p>
            </div>

            <div className="carousel-wrap row">
                {testimonials.slice(currentIndex, currentIndex + visibleItems).map((testimonial, index) => {
                    const isMiddleItem = index === middleIndex; // Check if this is the middle item
                    return (
                        <div
                            key={testimonial.id}
                            className={`col-md-6 col-lg-4 mx-auto testimonial-item position-relative bg-white p-5 mt-4 ${isMiddleItem ? 'middle-item' : ''}`}
                        >
                            <div className="">
                                <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                                <h5>{testimonial.first_h5}</h5>
                                <p>{testimonial.message}</p>
                                <div className="d-flex align-items-center">
                                    <img className="flex-shrink-0 rounded-circle" src={testimonial.image} alt="" />
                                    <div className="ms-3">
                                        <h5 className="mb-1">{testimonial.second_h5}</h5>
                                        <span>{testimonial.span}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
  );
};

export default FarmersTestimonials;
