import React from 'react';
import { Link } from 'react-router-dom';

export default function Feature() {
  return (
    <React.Fragment>
         {/* Page Header Start  */}
        <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <h1 className="display-3 mb-3 animated slideInDown">Projects</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Home</Link></li>
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Pages</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">Projects</li>
                    </ol>
                </nav>
            </div>
        </div>
         {/* Page Header End  */}


         {/* Feature Start  */}
        <div className="container-fluid bg-light bg-icon py-6">
            <div className="container">
                <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '800px'}}>
                    <h1 className="display-5 mb-3">Our Projects</h1>
                    <p>
                        We have farmer groups, cooperatives and individual 
                        farmers (both small and large scale farmers) 
                        with active members who are efficient and 
                        economically empowered in a sustainable way 
                        to use available resources.
                    </p>

                    <h3 style={{ textAlign: 'left' }}>On board Projects </h3>
                    <ul style={{ textAlign:'left' }}>
                        <li>
                            Irrigation as sustainable adaptation of coffee production
                        </li>

                        <li>
                            Coffee nursery 
                        </li>

                        <li>
                            Masaka Mixed Farmers and Traders Sacco Ltd
                        </li>
                    </ul>
                </div>

                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="" />
                            <h4 className="mb-3">
                                Masaka District Food Security Project under ALIVE Programme
                            </h4>
                            <p className="mb-4">
                                3897 households reached
                                85 Farmer Groups supported with improved planting materials (cassava and vegetable seeds)
                                Planted over 293,924 of long term agroforestry trees.
                                150 underground water tanks supported 
                                0ver 2065 wood saving stoves constructed
                                Planted over 220,342 meters of short term tree seeds
                                89 VSLAs strengthened where 2,589 females and 2,050 males access financial services.
                                MDFA SACCO established.
                                {/* MDFA cassava processing factory and bulking center established.
                                Over 300,000 kilograms of cassava chips processed and marketed under cassava value chain.
                                10,350 litres of passion fruit juice concentrate processed and marketed under passion fruit value chain.
                                800 bags of passion fruit bags bulked and marketed.
                                3,000 households empowered in joint decision making. */}
                            </p>
                            <p><b>Project Duration:</b> (2018-2022)</p>
                            <p><b>Donor:</b> Vi Agroforestry</p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="" />
                            <h4 className="mb-3">Gender Equality in reality</h4>
                            <p className="mb-4">
                                180 households trained on HRM concept
                                30 households supported on establishment of demonstration site of HRM.
                                97 women took up leadership and managerial positions.
                                120 households implemented their HRM
                                75 couples trained on HRM concept
                            </p>
                            <p><b>Project Duration:</b> (Jan - Dec 2020 )</p>
                            <p><b>Donor:</b> Postcode lottery</p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-3.png" alt="" />
                            <h4 className="mb-3">
                                Farmer Improved Livelihood Project (FOA)
                            </h4>
                            <p className="mb-4">
                                30 bio gas plants supported
                                120,000 long term trees planted
                                80,000 Short term trees planted
                                300 males and 450 females 
                                accessed financial services
                                70 farmer groups were capacitated in enterprise 
                                selection and linked to sustainable 
                                markets for their produce
                                Over 500 efficient wood saving stoves 
                                were constructed
                            </p>
                            <p><b>Project Duration:</b> (2016 - 2017)</p>
                            <p><b>Donor:</b> Vi Agroforestry</p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* Feature End  */}
    </React.Fragment>
  )
}
