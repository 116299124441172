import React from 'react';
import { Link } from 'react-router-dom';
import AboutPage from '../components/AboutPage';

export default function About() {
    return (
        <React.Fragment>
            {/* Page Header Start  */}
            <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container">
                    <h1 className="display-3 mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><Link className="text-body" to="#">Home</Link></li>
                            <li className="breadcrumb-item"><Link className="text-body" to="#">Pages</Link></li>
                            <li className="breadcrumb-item text-dark active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End  */}

            <AboutPage />
        </React.Fragment>
    )
}
