import React from 'react'
import { Link } from 'react-router-dom'

export default function Contact() {
  return (
    <React.Fragment>
         {/* Page Header Start */}
        <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <h1 className="display-3 mb-3 animated slideInDown">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Home</Link></li>
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Pages</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">Contact Us</li>
                    </ol>
                </nav>
            </div>
        </div>
        {/* Page Header End */}

        {/* Contact Start */}
        <div className="container-xxl py-6">
            <div className="container">
                <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                    <h1 className="display-5 mb-3">Contact Us</h1>
                    <p>
                        If you have any questions or enquiries a member of staff will 
                        always be happy to help. Feel free to contact us by telephone or email
                        and we will be sure to get back to you as soon as possible.
                    </p>
                </div>
                <div className="row g-5 justify-content-center">
                    <div className="col-lg-5 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-primary text-white d-flex flex-column justify-content-center h-100 p-5">
                            <h5 className="text-white">Call Us</h5>
                            <p className="mb-5">
                                <i className="fa fa-phone-alt me-3"></i>+256 701 412623  |  +256 702 752990  | <br /> +256 772 681724
                            </p>
                            <h5 className="text-white">Email Us</h5>
                            <p className="mb-5"><i className="fa fa-envelope me-3"></i>info@masakadfa.org</p>
                            <p className="mb-5"><i className="fa fa-envelope me-3"></i>masakadistrictfarmers@gmail.com</p>
                            
                            <h5 className="text-white">Office Address</h5>
                            <p className="mb-5"><i className="fa fa-map-marker-alt me-3"></i>Plot 4-6 Kirumba Close. P.O Box 154 Masaka</p>
                            <h5 className="text-white">Follow Us</h5>
                            <div className="d-flex pt-2">
                                <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="#"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="https://m.facebook.com/people/Masaka-District-Farmers-Association/100072903611165/"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="#"><i className="fab fa-youtube"></i></Link>
                                <Link className="btn btn-square btn-outline-light rounded-circle me-0" to="#"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                        <p className="mb-4">
                            For all enquiries, please contact us using the form below.
                        </p>
                        <form>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                        <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                        <label htmlFor="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: '200px'}}></textarea>
                                        <label htmlFor="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact End */}


        {/* Google Map Start */}
        <div className="container-xxl px-0 wow fadeIn" data-wow-delay="0.1s" style={{marginBottom: '-6px'}}>
            <iframe className="w-100" style={{height: '450px'}}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15959.007522431317!2d31.7428979!3d-0.3306554!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa6a90466522dd400!2sMasaka%20District%20Farmers%20Association!5e0!3m2!1sen!2sug!4v1662488045663!5m2!1sen!2sug"
                frameBorder="0" 
                allowFullScreen="" 
                aria-hidden="false" 
                title="Masaka District Farmers' Association"
                tabIndex="0"></iframe>
        </div>
        {/* Google Map End */}
    </React.Fragment>
  )
}
