import React from 'react';
import { Link } from 'react-router-dom';

export default function VisitFarmers() {
  return (
    <div className="container-fluid bg-primary bg-icon mt-5 py-6">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-md-8 wow fadeIn" data-wow-delay="0.1s">
                    <h1 className="display-5 text-white mb-3">Visit Our Farmers</h1>
                    <p className="text-white mb-0" style={{ fontSize: 25 }}>
                        We have farmer groups, cooperatives and individual farmers (both small and large scale farmers)
                        with active members who are efficient and economically empowered in a sustainable way to use available resources.
                    </p>
                </div>
                <div className="col-md-4 text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <Link className="btn btn-lg btn-secondary rounded-pill py-3 px-5" to="/farmers">Visit Now</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
