import React from 'react';
import { Link } from 'react-router-dom';
import SampleBlog from '../components/SampleBlog';

export default function Blog() {
  return (
    <React.Fragment>
        {/* Page Header Start  */}
        <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <h1 className="display-3 mb-3 animated slideInDown">Blog Grid</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Home</Link></li>
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Pages</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">Blog Grid</li>
                    </ol>
                </nav>
            </div>
        </div>
        {/* Page Header End  */}


        {/* Blog Start  */}
        <SampleBlog />
        {/* Blog End  */}
    </React.Fragment>
  )
}
