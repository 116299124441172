import React, { Component } from 'react';
import { 
  BrowserRouter as Router, 
  Link, 
  Route, 
  Routes,
} from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import $ from 'jquery'; // Import jQuery

import Menu from './components/Menu';
import Footer from './components/Footer';
// import "./App.css";
// pages
import Home from './pages/Home';
import About from './pages/About';
import Farmers from './pages/Farmers';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Projects from './pages/Projects';
import Testimonial from './pages/Testimonial';
import Default from './pages/404';


export default class App extends Component {
    constructor(props) {
      super(props);
      this.state = {
          showBackToTop: false
      };
      this.carouselRef = React.createRef();
      this.handleScroll = this.handleScroll.bind(this);
      this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
      this.initializeCarousel();

      // Scroll event listener for back-to-top button
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      // Remove scroll event listener when component is unmounted
      window.removeEventListener('scroll', this.handleScroll);
  }

  initializeCarousel() {
      const { document } = this.props;
      if (document && this.carouselRef.current) {
          // Initialize the carousel using jQuery
          $(this.carouselRef.current).owlCarousel({
              autoplay: true,
              smartSpeed: 1000,
              margin: 25,
              loop: true,
              center: true,
              dots: false,
              nav: true,
              navText: [
                  '<i class="bi bi-chevron-left"></i>',
                  '<i class="bi bi-chevron-right"></i>'
              ],
              responsive: {
                  0: { items: 1 },
                  768: { items: 2 },
                  992: { items: 3 }
              }
          });
      }
  }

  handleScroll() {
      if (window.scrollY > 300) {
          this.setState({ showBackToTop: true });
      } else {
          this.setState({ showBackToTop: false });
      }
  }

  scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render () {
    const { showBackToTop } = this.state;
    return (
      <React.Fragment>
        <Router>
          {/* Navbar Menu */}
          <Menu />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/farmers' element={<Farmers />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/Projects' element={<Projects />} />
            <Route path='/testimonial' element={<Testimonial />} />
            <Route path="*" element={<Default />} />
          </Routes>

          {/* Footer */}
          <Footer />

          <div>
            {/* Back to Top */}
            <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></Link>
          </div>

          {/* Back to Top Button */}
          {showBackToTop && (
              <Link to="#" onClick={this.scrollToTop}
                className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top">
                <i className="bi bi-arrow-up"></i>
              </Link>
          )}
        </Router>

      </React.Fragment>
    );
  }
}
