import React from 'react'
import { Link } from 'react-router-dom'

export default function TopNav() {
  return (
    <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
        <div className="col-lg-6 px-5 text-start">
            <small><i className="fa fa-map-marker-alt me-2"></i>Plot 4-6 Kirumba Close. P.O Box 154 Masaka</small>
            <small className="ms-4"><i className="fa fa-envelope me-2"></i>info@masakadfa.org</small>
        </div>
        <div className="col-lg-6 px-5 text-end">
            <small>Follow us:</small>
            <Link className="text-body ms-3" to="https://m.facebook.com/people/Masaka-District-Farmers-Association/100072903611165/"><i className="fab fa-facebook-f"></i></Link>
            <Link className="text-body ms-3" to="#"><i className="fab fa-twitter"></i></Link>
            <Link className="text-body ms-3" to="#"><i className="fab fa-linkedin-in"></i></Link>
            <Link className="text-body ms-3" to="#"><i className="fab fa-instagram"></i></Link>
        </div>
    </div>
  )
}
