import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AboutUsPage from '../components/AboutPage';
import Farmers from '../components/Farmers';
import FarmersTestimonials from '../components/FarmersTestimonials';
import SampleBlog from '../components/SampleBlog';
import { useSinglePrismicDocument } from '@prismicio/react';

// Import Bootstrap JavaScript (make sure it's included in your project)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


export default function Home() {
    const [prismicDocument] = useSinglePrismicDocument("home");

    // Initialize the carousel after the document is fully available and component is mounted
    useEffect(() => {
        if (prismicDocument && typeof window !== 'undefined') {
            const carouselElement = document.querySelector('#header-carousel');
            if (carouselElement) {
                // Initialize Bootstrap carousel
                const bootstrapCarousel = new window.bootstrap.Carousel(carouselElement, {
                    interval: 3000, // Set the autoplay interval
                    ride: 'carousel',
                });
                console.log(bootstrapCarousel);  // Optional: For testing purpose
            }
        }
    }, [prismicDocument]); // Run the effect only when `prismicDocument` is available

    return (
        <React.Fragment>
            {prismicDocument && (
                <div>
                    {/* Carousel Start */}
                    <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.2s">
                        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="w-100" src={prismicDocument.data.slider_image_one.url} alt="Slide 1" />
                                    <div className="carousel-caption">
                                        <div className="container">
                                            <div className="row justify-content-start">
                                                <div className="col-lg-7" style={{ textAlign: 'center' }}>
                                                    <h1 className="display-2 mb-5 animated slideInDown">
                                                        {prismicDocument.data.slider_text_one[0].text}
                                                    </h1>
                                                    <Link to="/farmers" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">
                                                        {prismicDocument.data.btn_text[0].text}
                                                    </Link>
                                                    <a href="https://flutterwave.com/donate/te9nhnou24vy" 
                                                       className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                                                       target="_blank" rel="noopener noreferrer">
                                                        {prismicDocument.data.btn_text1[0].text}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img className="w-100" src={prismicDocument.data.slider_image_two.url} alt="Slide 2" />
                                    <div className="carousel-caption">
                                        <div className="container">
                                            <div className="row justify-content-start">
                                                <div className="col-lg-7" style={{ textAlign: 'center' }}>
                                                    <h1 className="display-2 mb-5 animated slideInDown">
                                                        {prismicDocument.data.slider_text_two[0].text}
                                                    </h1>
                                                    <Link to="/farmers" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">
                                                        {prismicDocument.data.btn_text[0].text}
                                                    </Link>
                                                    <a href="https://flutterwave.com/donate/te9nhnou24vy" 
                                                       className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                                                       target="_blank" rel="noopener noreferrer">
                                                        {prismicDocument.data.btn_text1[0].text}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>

                        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    {/* Carousel End */}

                    <AboutUsPage /> 
                    
                    {/* Product Start */}
                    <Farmers />

                    {/* Testimonial Start */}
                    <FarmersTestimonials />
                    {/* Testimonial End */}

                    {/* Blog Start */}
                    <div className="container-xxl py-5">
                        <div className="container">
                            <SampleBlog />
                        </div>
                    </div>
                    {/* Blog End */}
                </div>
            )}
        </React.Fragment>
    );
}
