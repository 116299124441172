import React from 'react'
import { Link } from 'react-router-dom';
import GoalsAndObjectives from '../components/Objectives';
import VisitFarmers from './VisitFarmers';
import Partners from './Partners';
import { useSinglePrismicDocument } from '@prismicio/react';

export default function About() {
    const [document] = useSinglePrismicDocument("about_us")

    return (
        <div>
            { document && (
                <div>
                    <div className="container-fluid bg-light bg-icon my-5 py-6">
                        <div className="container">
                            <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWwidth: '500px'}}>
                                <h1 className="display-5 mb-3">
                                    {document.data.title[0].text}
                                </h1>
                                <p>
                                    {document.data.paragraph[0].text}
                                </p>
                            </div>
                            <div className="row g-4">
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="bg-white text-center h-100 p-4 p-xl-5">
                                        <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="mdfa" />
                                        <h4 className="mb-3">{document.data.vison[0].text}</h4>
                                        <p className="mb-4">
                                            {document.data.vison_text[0].text}
                                        </p>
                                        {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 
                                            rounded-pill" to="/farmers">
                                                {document.data.btn_text[0].text}
                                        </Link> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="bg-white text-center h-100 p-4 p-xl-5">
                                        <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="mdfa" />
                                        <h4 className="mb-3">{document.data.mission[0].text}</h4>
                                        <p className="mb-4">
                                            {document.data.mission_text[0].text}
                                        </p>
                                        {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="/farmers">
                                            {document.data.btn_text[0].text}
                                        </Link> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="bg-white text-center h-100 p-4 p-xl-5">
                                        <img className="img-fluid mb-4" src="assets/img/icon-3.png" alt="mdfa" />
                                        <h4 className="mb-3">{document.data.farmers[0].text}</h4>
                                        <p className="mb-4">
                                            {document.data.structure[0].text}
                                        </p>
                                        {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="/farmers">
                                            {document.data.btn_text[0].text}
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Feature End */}


                    {/* About Start */}
                    <div className="container-xxl py-5">
                        <div className="container">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="about-img position-relative overflow-hidden p-5 pe-0">
                                        <img className="img-fluid w-100"
                                            style={{height: '500px'}}
                                            src="assets/img/blog-3.jpg" alt='mdfa' />
                                    </div>
                                </div>
                                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                    <h1 className="display-5 mb-4">{document.data.structure[0].text}</h1>
                                    <p className="mb-4"> 
                                        {document.data.structure_info[0].text}
                                    </p>
                                    <p><i className="fa fa-check text-primary me-3"></i>
                                        {document.data.structure_info2[0].text}
                                    </p>
                                    <p><i className="fa fa-check text-primary me-3"></i>
                                        {document.data.structure_info3[0].text}
                                    </p>
                                    <p><i className="fa fa-check text-primary me-3"></i>
                                        {document.data.structure_info4[0].text}
                                    </p>
                                    <Link className="btn btn-primary rounded-pill py-3 px-5 mt-3" to="/contact"> {document.data.btn_text[0].text} </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* About End */}

            {/* Firm Visit Start  */}
            <VisitFarmers />
            {/* Firm Visit End */}

            <GoalsAndObjectives />

            {/* Partners */}
            <Partners />
            
        </div>
    )
}
