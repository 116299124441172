import React from 'react';
import { Link } from 'react-router-dom';

export default function Default() {
  return (
    <React.Fragment>
        {/* Page Header Start  */}
        <div class="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
            <div class="container">
                <h1 class="display-3 mb-3 animated slideInDown">404 Error</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item"><Link class="text-body" to="#">Home</Link></li>
                        <li class="breadcrumb-item"><Link class="text-body" to="#">Pages</Link></li>
                        <li class="breadcrumb-item text-dark active" aria-current="page">404 Error</li>
                    </ol>
                </nav>
            </div>
        </div>
        {/* Page Header End  */}


        {/* 404 Start  */}
        <div class="container-xxl py-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                        <h1 class="display-1">404</h1>
                        <h1 class="mb-4">Page Not Found</h1>
                        <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                        <Link class="btn btn-primary rounded-pill py-3 px-5" to="/">Go Back To Home</Link>
                    </div>
                </div>
            </div>
        </div>
        {/* 404 End  */}
    </React.Fragment>
  )
}
