import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h1 className="fw-bold text-primary mb-4">
                        <img src="assets/img/logo.png" alt='MDFA' />
                    </h1>
                    <p>
                        Masaka District Farmers Association Ltd (MDFA) is anon-Government 
                        Farmer Owned Organization that was established in 1992 and 
                        registered in 1998 with the Registrar of Companies as a Company 
                        Limited by guarantee. 
                    </p>
                    <div className="d-flex pt-2">
                        <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="#"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="#"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-square btn-outline-light rounded-circle me-1" to="#"><i className="fab fa-youtube"></i></Link>
                        <Link className="btn btn-square btn-outline-light rounded-circle me-0" to="#"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p><i className="fa fa-map-marker-alt me-3"></i>Plot 4-6 Kirumba Close. P.O Box 154 Masaka</p>
                    <p><i className="fa fa-phone-alt me-3"></i>+256 701 412623 / +256 702 752990 / +256 772 681724</p>
                    <p><i className="fa fa-envelope me-3"></i>info@masakadfa.org</p>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Quick Links</h4>
                    <Link className="btn btn-link" to="./about">About Us</Link>
                    <Link className="btn btn-link" to="./contact">Contact Us</Link>
                    <Link className="btn btn-link" to="./blog">Blog</Link>
                    <Link className="btn btn-link" to="./projects">Projects</Link>
                    <Link className="btn btn-link" to="./testimonial">Testimonial</Link>
                    <Link className="btn btn-link" to="./support">Support</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Newsletter</h4>
                    <p>
                        Register to our newsletter and don’t miss anything anymore.
                        We promise we will not spam you !
                    </p>
                    <div className="position-relative mx-auto" style={{maxWwidth: '400px'}}>
                        <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link to="#">Masaka District Farmers' Association</Link>, All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        Designed By <Link to="https://skyllaconnect.com">Skylla Connect</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}