import React from 'react';
// import Cards from './cards';

export default function GoalsAndObjectives() {
    return (
        <div className="container-fluid bg-light bg-icon py-6">
            <div className="container">
                <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                    <h1 className="display-5 mb-3">Goals and Objectives</h1>
                    <p>
                        We are currently reaching 3,556 people in over 100 Farmer Groups within the sub-counties of Buwunga, Mukungwe, Bukakata, Kabonera and Kyanamukaka in both Masaka City and District.
                    </p>
                </div>

                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="madfa" />
                            {/* <h4 className="mb-3">Natural Process</h4> */}
                            <p className="mb-4">
                                To unite and harness all farmers engaged in agro industry in Masaka District.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="madfa" />
                            {/* <h4 className="mb-3">Organic Products</h4> */}
                            <p className="mb-4">
                                To establish a common front and forum for all agro-related 
                                associations and Organisations in Masaka District for the 
                                purpose of promoting coordinating and safe guarding their 
                                interests and activities especially when dealing with Government 
                                and other development partners.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-3.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To be a legal entity through which the farmers’ 
                                voice is amplified to leverage on Government and 
                                Non-Government supported projects to improve the 
                                livelihoods of our members.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                Promotion of modernization, diversification, irrigation, 
                                biodiversity, agroforestry and encouragement of availability 
                                of plant and animal diagnostic services.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.9s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To develop advanced agricultural industries involving 
                                manufacturing of final products.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.1s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-3.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To link farmers to sustainable agro-industrial markets and to 
                                ensure market information dissemination to the members.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.3s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To increase access to financial services to the 
                                members by strengthening Masaka District Farmers SACCO.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To ensure efficient service delivery to the members by increasing 
                                the pool of resources through; membership, subscription, donations, 
                                and fund raising.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.7s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-3.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To promote environmental protection through; Sustainable 
                                Agricultural Land use Management (SALM): 
                                (Agroforestry, energy saving options, Intergrated Pest 
                                Management, organic farming viz- bio char fertilizer, 
                                liquid manure, organic pesticides), and other good 
                                agronomic practices.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.9s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-1.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To promote empowerment of persons with HIV, women, youth, children, 
                                and PWDs through sensitization and active participation in all 
                                the Association’s development activities
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src="assets/img/icon-2.png" alt="madfa" />
                            {/* <h4 className="mb-3">Biologically Safe</h4> */}
                            <p className="mb-4">
                                To promote joint decision making in households through gender mainstreaming.
                            </p>
                            {/* <Link className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" to="#">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <Cards /> */}
        </div>
    )
}
