import React from 'react'

export default function Partners() {
  return (
    <div>
        <section id="clients" className="section-bg">
            <div className="container">

                <div className="section-header">
                    <h3 className='part'>Our Partners and Donors</h3>
                    <p className='partner'>Meet our happy Partners and Donors</p>
                </div>

                <div className="row no-gutters clients-wrap clearfix wow fadeInUp" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/germ.png" alt="" style={{ maxWidth: '175%' }} />
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p1.png" className="img-fluid" alt="" />
                        </div>
                    </div>
          
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p2.png" className="img-fluid" alt="" />
                        </div>
                    </div>
        
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p3.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>
          
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p4.png" className="img-fluid" alt="" />
                        </div>
                    </div>
          
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p5.png" className="img-fluid" alt="" />
                        </div>
                    </div>
        
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p6.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p7.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>
          
                    <div className="col-lg-3 col-md-4 col-xs-6">
                        <div className="client-logo">
                            <img src="assets/img/p8.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>
  )
}
